import React, { useEffect, useState } from "react";
import { searchQuery } from "../composable/useSearchable";
import * as queries from "../graphql/queries";
import { ListContactsQuery } from "../API";
import { Link } from "react-router-dom";

const variables = {};

export const Contact: React.VFC = () => {
  const [contactList, setContactList] = useState<
    ListContactsQuery | undefined
  >();
  useEffect(() => {
    const getData = async () => {
      const result = await searchQuery(queries.listContacts, variables);
      setContactList(result.data);
    };
    getData();
  }, []);

  return (
    <>
      <div className="m-auto">
        <div className="pt-8">
          <span className="text-4xl font-bold ml-5">お問い合わせ</span>
        </div>
        <table className="m-auto border-2 mt-8">
          <thead>
            <tr className="text-center">
              <th className="px-6 py-3 bg-indigo-500 text-white">ID</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">タイトル</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">
                ニックネーム
              </th>
              <th className="px-6 py-3 bg-indigo-500 text-white">
                メールアドレス
              </th>
              <th className="px-6 py-3 bg-indigo-500 text-white">返信有無</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">登録日</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">更新日</th>
            </tr>
          </thead>
          <tbody>
            {contactList?.listContacts?.items.map((contact: any) => {
              return (
                <>
                  <tr className="text-center">
                    <td className="px-6 py-3 text-indigo-500">
                      <Link
                        to={`contact/${JSON.parse(contact?.title).question}`}
                        state={{ id: contact?.id }}
                      >
                        {contact?.id}
                      </Link>
                    </td>
                    <td className="px-6 py-3">
                      {JSON.parse(contact?.title).question}
                    </td>
                    <td className="px-6 py-3">{contact?.nickname}</td>
                    <td className="px-6 py-3">{contact?.email}</td>
                    <td
                      className={
                        "px-6 py-3 " +
                        (contact?.status === 0
                          ? "text-red-700"
                          : "text-blue-500")
                      }
                    >
                      {contact?.status === 0 ? "返信待ち" : "返信済み"}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(contact?.createdAt).toLocaleString()}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(contact?.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
