import React, { useEffect, useState } from "react";
import { GetCategoryMasterQuery } from "../API";
import { getQuery } from "../composable/useQuerys";
import { updateMutation } from "../composable/useMutations";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useLocation } from "react-router-dom";

export default function CategoryMasterInfo() {
  const [showModal, setShowModal] = useState(false);
  const [categoryList, setCategoryList] = useState<GetCategoryMasterQuery>();
  const [category, setCategory] = useState<string>();
  const [typeName, setTypeName] = useState<string>();
  const location = useLocation();
  const id = location.state.id;
  useEffect(() => {
    const getData = async () => {
      const result: any = await getQuery(queries.getCategoryMaster, id);
      setCategoryList(result.data);
    };
    getData();
  }, []);

  async function onsubmit() {
    const categoryData = {
      id: categoryList?.getCategoryMaster?.id,
      category: categoryList?.getCategoryMaster?.category,
      typeName: categoryList?.getCategoryMaster?.typeName,
    };
    const result = await updateMutation(
      mutations.updateCategoryMaster,
      categoryData
    );
    if (result) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="ml-8 text-start mt-8">
        <span className="text-3xl font-bold">カテゴリー詳細情報</span>
      </div>
      <div className="mt-8 max-w-3xl m-auto">
        <div className="border border-gray-200 rounded-lg shadow">
          <table className="mt-8 text-start m-auto w-[75%]">
            <tr>
              <td className="px-6 py-3 font-semibold">ID</td>
              <td className="px-6 py-3">
                {categoryList?.getCategoryMaster?.id}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">カテゴリー</td>
              <td className="px-6 py-3">
                {categoryList?.getCategoryMaster?.category}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">参照テーブル</td>
              <td className="px-6 py-3">
                {categoryList?.getCategoryMaster?.typeName}
              </td>
            </tr>
          </table>
          <div className="flex justify-center items-center mt-5 mb-5">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => setShowModal(true)}
            >
              修正
            </button>
          </div>
        </div>
        <div>
          {showModal ? (
            <>
              <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0  h-full bg-black opacity-40"
                  onClick={() => setShowModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="sm:flex">
                      <div className=" sm:text-left">
                        <h4 className="text-lg font-bold text-gray-800">
                          カテゴリー情報修正
                        </h4>
                        <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                          <div className="relative">
                            <div>カテゴリー</div>
                            <div className="mb-10">
                              <input
                                type="text"
                                className="border form-input w-[300px] md:w-[350]"
                                value={categoryList?.getCategoryMaster?.category?.toString()}
                                onChange={(event) =>
                                  setCategory(event.target.value)
                                }
                              />
                            </div>
                            <div>参照テーブル名</div>
                            <div className="mb-10">
                              <input
                                type="text"
                                className="border form-input w-[300px] md:w-[350]"
                                value={categoryList?.getCategoryMaster?.typeName?.toString()}
                                onChange={(event) =>
                                  setTypeName(event.target.value)
                                }
                              />
                            </div>
                            <div className="mt-10">
                              <div className="items-center gap-2 mt-3 sm:flex">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={() => setShowModal(false)}
                                >
                                  キャンセル
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => onsubmit()}
                                >
                                  送信
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
