import { API } from "aws-amplify";

export const updateMutation = async (query: string, params: any) => {
  try {
    const result = await API.graphql({
      query: query,
      variables: { input: params },
      authMode: "API_KEY",
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
