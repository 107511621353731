import React, { useEffect, useState } from "react";
import { GetAdminQuery } from "../API";
import { getQuery } from "../composable/useQuerys";
import { updateMutation } from "../composable/useMutations";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useLocation } from "react-router-dom";

export default function AdminInfo() {
  const [adminList, setAdminList] = useState<GetAdminQuery>();
  const [showOkModal, setShowOkModal] = useState(false);
  const [showNoModal, setShowNoModal] = useState(false);
  const location = useLocation();
  const id: string = location.state.id;
  useEffect(() => {
    const getData = async () => {
      const result: any = await getQuery(queries.getAdmin, id);
      setAdminList(result.data);
    };
    getData();
  }, []);

  async function setAuth() {
    const adminData = {
      id: adminList?.getAdmin?.id,
      email: adminList?.getAdmin?.email,
      adminName: adminList?.getAdmin?.adminName,
      isAdmin: true,
      phoneNumber: adminList?.getAdmin?.phoneNumber,
    };
    const result = await updateMutation(mutations.updateAdmin, adminData);
    if (result) {
      window.location.reload();
    }
  }

  async function noAuth() {
    const adminData = {
      id: adminList?.getAdmin?.id,
      email: adminList?.getAdmin?.email,
      adminName: adminList?.getAdmin?.adminName,
      isAdmin: false,
      phoneNumber: adminList?.getAdmin?.phoneNumber,
    };
    // console.log(contactData);
    const result = await updateMutation(mutations.updateAdmin, adminData);
    if (result) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="ml-8 text-start mt-8">
        <span className="text-3xl font-bold">管理者詳細情報</span>
      </div>
      <div className="mt-8 max-w-3xl m-auto">
        <div className="border border-gray-200 rounded-lg shadow">
          <table className="mt-8 text-start m-auto w-[75%]">
            <tr>
              <td className="px-6 py-3 font-semibold">ID</td>
              <td className="px-6 py-3">{adminList?.getAdmin?.id}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">ニックネーム</td>
              <td className="px-6 py-3"> {adminList?.getAdmin?.adminName}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">メールアドレス</td>
              <td className="px-6 py-3">{adminList?.getAdmin?.email}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-bold">携帯電話番号</td>
              <td className="px-6 py-3">{adminList?.getAdmin?.phoneNumber}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-bold">権限有無</td>
              <td
                className={
                  "px-6 py-3 " +
                  (adminList?.getAdmin?.isAdmin === false
                    ? "text-red-700"
                    : "text-blue-500")
                }
              >
                {adminList?.getAdmin?.isAdmin === false
                  ? "権限無し"
                  : "権限有り"}
              </td>
            </tr>
          </table>
          <div className="flex justify-center items-center mt-5 mb-5">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mr-3"
              type="button"
              onClick={() => setShowOkModal(true)}
            >
              権限付与
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => setShowNoModal(true)}
            >
              権限無し
            </button>
          </div>
        </div>
        <div>
          {showOkModal ? (
            <>
              <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setShowOkModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="mt-3 sm:flex">
                      <div className="mt-2 sm:ml-4 sm:text-right">
                        <h4 className="text-lg font-bold text-gray-800">
                          権限付与しますか？
                        </h4>
                        <div className="mt-5 md:mt-10 m-auto text-gray-500 p-3 items-end">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-3"
                            onClick={() => setShowOkModal(false)}
                          >
                            キャンセル
                          </button>
                          <button
                            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mr-3"
                            onClick={() => setAuth()}
                          >
                            権限付与
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div>
          {showNoModal ? (
            <>
              <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setShowNoModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="mt-3 sm:flex">
                      <div className="mt-2 sm:ml-4 sm:text-left">
                        <h4 className="text-lg font-bold text-gray-800">
                          権限を無くしますか？
                        </h4>
                        <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3 items-end">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-3"
                            onClick={() => setShowNoModal(false)}
                          >
                            キャンセル
                          </button>
                          <button
                            className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mr-3"
                            onClick={() => noAuth()}
                          >
                            権限無し
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
