import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function header({ signOut, user }: Props) {
  return (
    <>
      <nav className="top-0 ml-64 z-50 bg-white dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex justify-end">
            <span className="mt-1 text-gray-400">
              {user?.attributes?.email.split("@")[0]}さん
            </span>
            <button
              onClick={signOut}
              className="ml-5 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mr-3"
            >
              ログアウト
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default withAuthenticator(header);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}
