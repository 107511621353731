import React, { useEffect, useState } from "react";
import { searchQuery } from "../composable/useSearchable";
import * as queries from "../graphql/queries";
import { ListAdminsQuery } from "../API";
import { Link } from "react-router-dom";

const variables = {};

export const Admin: React.VFC = () => {
  const [adminList, setAdminList] = useState<ListAdminsQuery | undefined>();
  useEffect(() => {
    const getData = async () => {
      const result = await searchQuery(queries.listAdmins, variables);
      setAdminList(result.data);
    };
    getData();
  }, []);

  return (
    <>
      <div className="m-auto">
        <div className="pt-8">
          <span className="text-4xl font-bold ml-5">管理者</span>
        </div>
        <table className="m-auto border-2 mt-8">
          <thead>
            <tr className="text-center">
              <th className="px-6 py-3 bg-indigo-500 text-white">ID</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">
                メールアドレス
              </th>
              <th className="px-6 py-3 bg-indigo-500 text-white">
                携帯電話番号
              </th>
              <th className="px-6 py-3 bg-indigo-500 text-white">権限有無</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">登録日</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">更新日</th>
            </tr>
          </thead>
          <tbody>
            {adminList?.listAdmins?.items.map((admin: any) => {
              return (
                <>
                  <tr className="text-center">
                    <td className="px-6 py-3 text-indigo-500">
                      <Link to={admin?.id} state={{ id: admin?.id }}>
                        {admin?.id}
                      </Link>
                    </td>
                    <td className="px-6 py-3">{admin?.email}</td>
                    <td className="px-6 py-3">{admin?.phoneNumber}</td>
                    <td
                      className={
                        "px-6 py-3 " +
                        (admin?.isAdmin === false
                          ? "text-red-700"
                          : "text-blue-500")
                      }
                    >
                      {admin?.isAdmin === false ? "権限無し" : "権限有り"}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(admin?.createdAt).toLocaleString()}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(admin?.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
