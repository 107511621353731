/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://rtvxo7mkovhp5oe7hce3gnhqo4.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-eeqi2hmvzjac3l4mkvn7m4am2q",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2601edd0-36a2-4361-a916-7d81097a9b14",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_oBJUSYPCk",
    "aws_user_pools_web_client_id": "6l25p0comgi9snqcf9p9nth96f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "daon-files20748-staging",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
