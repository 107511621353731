import React, { useEffect, useState } from "react";
import { GetNoticeQuery } from "../API";
import { getQuery } from "../composable/useQuerys";
import { updateMutation } from "../composable/useMutations";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import DatePicker from "react-datepicker";

export default function NoticeInfo() {
  const [content, setContent] = useState<string>("");
  const [noticeList, setNoticeList] = useState<GetNoticeQuery>();
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const location = useLocation();
  const id = location.state.id;
  const getContent = (event: any) => {
    setContent(event.target.value);
  };
  const contents: string | any = noticeList?.getNotice?.content;
  useEffect(() => {
    const getData = async () => {
      const result: any = await getQuery(queries.getNotice, id);
      setNoticeList(result.data);
    };
    getData();
  }, []);

  async function onsubmit() {
    const noticeData = {
      id: noticeList?.getNotice?.id,
      title: noticeList?.getNotice?.title,
      content: noticeList?.getNotice?.content,
      isView: noticeList?.getNotice?.isView,
      viewCount: noticeList?.getNotice?.viewCount,
      startTime: noticeList?.getNotice?.startTime,
      endTime: noticeList?.getNotice?.endTime,
    };
    const result = await updateMutation(mutations.updateNotice, noticeData);
    if (result) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="ml-8 text-start mt-8">
        <span className="text-3xl font-bold">プレスリリース詳細情報</span>
      </div>
      <div className="mt-8 max-w-3xl m-auto">
        <div className="border border-gray-200 rounded-lg shadow">
          <table className="mt-8 text-start m-auto w-[75%]">
            <tr>
              <td className="px-6 py-3 font-semibold">ID</td>
              <td className="px-6 py-3">{noticeList?.getNotice?.id}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">タイトル</td>
              <td className="px-6 py-3"> {noticeList?.getNotice?.title}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">カテゴリー</td>
              <td className="px-6 py-3">
                {noticeList?.getNotice?.category?.category}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">本文</td>
              <td className="px-6 py-3">
                <div dangerouslySetInnerHTML={{ __html: contents }}></div>
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-bold">開始時間</td>
              <td className="px-6 py-3">{noticeList?.getNotice?.startTime}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-bold">廃止時間</td>
              <td className="px-6 py-3">{noticeList?.getNotice?.endTime}</td>
            </tr>
          </table>
          <div className="flex justify-center items-center mt-5 mb-5">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => setShowModal(true)}
            >
              修正
            </button>
          </div>
        </div>
        <div>
          {showModal ? (
            <>
              <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setShowModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-2xl p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="sm:flex">
                      <div className="sm:text-left">
                        <h4 className="text-lg font-bold text-gray-800">
                          プレスリリース修正
                        </h4>
                        <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                          <div className="relative">
                            <div>タイトル</div>
                            <div className="mb-10">
                              <input
                                type="text"
                                className="border form-input w-[300px] md:w-[350]"
                              />
                            </div>
                            <div>本文</div>
                            <div className="mb-10">
                              <ReactQuill
                                theme="snow"
                                className="form-input px-4 w-full h-full"
                                onChange={setContent}
                                value={content}
                              ></ReactQuill>
                            </div>
                            <div>開始時間</div>
                            <div className="mb-10">
                              <DatePicker
                                selected={startTime}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                onChange={setStartTime}
                                value={startTime}
                              ></DatePicker>
                            </div>
                            <div>廃止時間</div>
                            <div className="mb-10">
                              <DatePicker
                                selected={endTime}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                onChange={setEndTime}
                                value={endTime}
                              ></DatePicker>
                            </div>
                            <div className="mt-10">
                              <div className="items-center gap-2 mt-3 sm:flex">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={() => setShowModal(false)}
                                >
                                  キャンセル
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => onsubmit()}
                                >
                                  送信
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
