import {
  withAuthenticator,
  WithAuthenticatorProps,
  translations,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import { Amplify, I18n } from "aws-amplify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";
import NotAuth from "./components/login/NotAuth";
import { Contact } from "./page/Contact";
import { Admin } from "./page/Admin";
import { Notice } from "./page/Notice";
import { CategoryMaster } from "./page/CategoryMaster";
import ContactInfo from "./page/ContactInfo";
import AdminInfo from "./page/AdminInfo";
import NoticeInfo from "./page/NoticeInfo";
import CategoryMasterInfo from "./page/CategoryMasterInfo";
import * as queries from "./graphql/queries";
import { getQuery } from "./composable/useQuerys";
import { useEffect, useState } from "react";
import { GetAdminQuery } from "./API";

Amplify.configure(awsExports);
I18n.putVocabularies(translations);
I18n.setLanguage("ja");

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function App({ isPassedToWithAuthenticator, user }: Props) {
  const [admin, setAdmin] = useState<GetAdminQuery | undefined | any>();
  if (!isPassedToWithAuthenticator) {
    throw new Error(`isPassedToWithAuthenticator was not provided`);
  }

  useEffect(() => {
    const id: string | undefined = user?.username;
    const getData = async () => {
      const result = await getQuery(queries.getAdmin, id);
      setAdmin(result);
    };
    getData();
  }, []);
  const auth = admin?.data.getAdmin?.isAdmin;
  localStorage.setItem("auth", auth);
  return localStorage.getItem("auth") ? (
    <div className="App">
      <BrowserRouter>
        <Header isPassedToWithAuthenticator></Header>
        <Sidebar isPassedToWithAuthenticator></Sidebar>
        <div className="m-auto md:ml-64">
          <Routes>
            <Route path="/" element={<Contact />}></Route>
            <Route path="contact/:title" element={<ContactInfo />}></Route>
            <Route path="/notice" element={<Notice />}></Route>
            <Route path="notice/:id" element={<NoticeInfo />}></Route>
            <Route path="/category" element={<CategoryMaster />}></Route>
            <Route path="category/:id" element={<CategoryMasterInfo />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="admin/:id" element={<AdminInfo />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  ) : (
    <NotAuth isPassedToWithAuthenticator />
  );
}

const formFields = {
  signUp: {
    "custom:groups": {
      label: "Admin",
      placeholder: "adminと入力",
    },
    phone_number: {
      dialCodeList: ["+81"],
    },
  },
};
export default withAuthenticator(App, { formFields });
