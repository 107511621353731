import React, { useEffect, useState } from "react";
import { searchQuery } from "../composable/useSearchable";
import * as queries from "../graphql/queries";
import { ListCategoryMastersQuery, ListNoticesQuery } from "../API";
import { Link } from "react-router-dom";
import { updateMutation } from "../composable/useMutations";
import * as mutations from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const variables = {};
const id = uuidv4();
export const Notice: React.VFC = () => {
  const [showModal, setShowModal] = useState(false);
  const [noticeList, setNoticeList] = useState<ListNoticesQuery | undefined>();
  const [categoryList, setCategoryList] = useState<
    ListCategoryMastersQuery | undefined
  >();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  useEffect(() => {
    const getData = async () => {
      const result = await searchQuery(queries.listNotices, variables);
      setNoticeList(result.data);
    };
    const value = { filter: { typeName: { eq: Notice } } };
    const getCategoryData = async () => {
      const result = await searchQuery(queries.listCategoryMasters, value);
      setCategoryList(result.data);
    };
    getData();
    getCategoryData();
  }, []);

  async function createNotice() {
    const noticeData = {
      id: id,
      title: title,
      noticeCategoryId: category,
      content: content,
      isView: false,
      viewCount: 0,
      startTime: new Date(startTime).toISOString(),
      endTime: new Date(endTime).toISOString(),
    };
    const result = await updateMutation(mutations.createNotice, noticeData);
    if (result) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="m-auto">
        <div className="pt-8">
          <span className="text-4xl font-bold ml-5">プレスリリース</span>
        </div>
        <div className="p-10">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            onClick={() => setShowModal(true)}
          >
            プレスリリース追加
          </button>
        </div>
        <table className="m-auto border-2 mt-8">
          <thead>
            <tr className="text-center">
              <th className="px-6 py-3 bg-indigo-500 text-white">ID</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">タイトル</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">カテゴリー</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">ビュー</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">表示有無</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">登録日</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">更新日</th>
            </tr>
          </thead>
          <tbody>
            {noticeList?.listNotices?.items.map((notice: any) => {
              return (
                <>
                  <tr className="text-center">
                    <td className="px-6 py-3 text-indigo-500">
                      <Link to={`${notice?.id}`} state={{ id: notice?.id }}>
                        {notice?.id}
                      </Link>
                    </td>
                    <td className="px-6 py-3">{notice?.title}</td>
                    <td className="px-6 py-3">{notice?.category?.category}</td>
                    <td className="px-6 py-3">{notice?.viewCount}</td>
                    <td
                      className={
                        "px-6 py-3 " +
                        (notice?.isView === false
                          ? "text-red-700"
                          : "text-blue-500")
                      }
                    >
                      {notice?.status === 0 ? "非表示" : "表示"}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(notice?.createdAt).toLocaleString()}
                    </td>
                    <td className="px-6 py-3">
                      {new Date(notice?.updatedAt).toLocaleString()}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      {showModal ? (
        <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className=" sm:text-left">
                <h4 className="text-lg font-bold text-gray-800">
                  プレスリリース作成
                </h4>
                <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                  <div>タイトル</div>
                  <div className="mb-10">
                    <input
                      type="text"
                      className="border form-input w-[300px] md:w-[350px]"
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                  <div>カテゴリー</div>
                  <div className="mb-10">
                    <select
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      {categoryList?.listCategoryMasters?.items.map(
                        (category: any) => {
                          return (
                            <>
                              <option value={""}></option>
                              <option value={category.id}>
                                {category.category}
                              </option>
                            </>
                          );
                        }
                      )}
                      ;
                    </select>
                  </div>
                  <div>本文</div>
                  <div className="mb-10">
                    <ReactQuill
                      theme="snow"
                      className="form-input px-4 w-full h-full"
                      onChange={setContent}
                      value={content}
                    ></ReactQuill>
                  </div>
                  <div>開始時間</div>
                  <div className="mb-10">
                    <DatePicker
                      selected={startTime}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      onChange={setStartTime}
                      value={startTime}
                    ></DatePicker>
                  </div>
                  <div>廃止時間</div>
                  <div className="mb-10">
                    <DatePicker
                      selected={endTime}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                      onChange={setEndTime}
                      value={endTime}
                    ></DatePicker>
                  </div>
                  <div className="mt-10">
                    <div className="items-center gap-2 mt-3 sm:flex">
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                        onClick={() => setShowModal(false)}
                      >
                        キャンセル
                      </button>
                      <button
                        className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                        onClick={() => setShowConfirmModal(true)}
                      >
                        作成
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showConfirmModal ? (
        <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowConfirmModal(false)}
          ></div>
          <div className="flex items-center h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="sm:text-left">
                <h4 className="text-lg font-bold text-gray-800">
                  プレスリリースを作成しますか？
                </h4>
                <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                  <div className="relative">
                    <div className="mt-10">
                      <div className="items-center gap-2 mt-3 sm:flex">
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-sm text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                          onClick={() => setShowConfirmModal(false)}
                        >
                          キャンセル
                        </button>
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-sm text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2
                          "
                          onClick={() => createNotice()}
                        >
                          プレスリリース作成
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
