import React, { useEffect, useState } from "react";
import { GetContactQuery } from "../API";
import { getQuery } from "../composable/useQuerys";
import { updateMutation } from "../composable/useMutations";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useLocation } from "react-router-dom";

export default function ContactInfo() {
  const [content, setContent] = useState<string>("");
  const [contactList, setContactList] = useState<GetContactQuery>();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const id = location.state.id;
  const getContent = (event: any) => {
    setContent(event.target.value);
  };
  useEffect(() => {
    const getData = async () => {
      const result: any = await getQuery(queries.getContact, id);
      setContactList(result.data);
    };
    getData();
  }, []);
  const title = `RE: ${
    contactList?.getContact?.title
      ? JSON.parse(contactList?.getContact?.title as string).question
      : undefined
  }`;

  async function onsubmit() {
    const contactData = {
      id: contactList?.getContact?.id,
      email: contactList?.getContact?.email,
      title: JSON.stringify({
        question: contactList?.getContact?.title
          ? JSON.parse(contactList?.getContact?.title as string).question
          : undefined,
        answer: title,
      }),
      content: JSON.stringify({
        question: contactList?.getContact?.content
          ? JSON.parse(contactList?.getContact?.content as string).question
          : undefined,
        answer: content,
      }),
      status: 1,
    };
    const result = await updateMutation(mutations.updateContact, contactData);
    if (result) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="ml-8 text-start mt-8">
        <span className="text-3xl font-bold">お問い合わせ詳細情報</span>
      </div>
      <div className="mt-8 max-w-3xl m-auto">
        <div className="border border-gray-200 rounded-lg shadow">
          <div className="mt-5">
            <span className="font-bold text-2xl">お問い合わせ内容</span>
          </div>
          <table className="mt-8 text-start m-auto w-[75%]">
            <tr>
              <td className="px-6 py-3 font-semibold">ID</td>
              <td className="px-6 py-3">{contactList?.getContact?.id}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">ニックネーム</td>
              <td className="px-6 py-3">
                {" "}
                {contactList?.getContact?.nickname}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">メールアドレス</td>
              <td className="px-6 py-3">{contactList?.getContact?.email}</td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-semibold">タイトル</td>
              <td className="px-6 py-3">
                {contactList?.getContact?.title
                  ? JSON.parse(contactList?.getContact?.title as string)
                      .question
                  : undefined}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-3 font-bold">お問い合わせ内容</td>
              <td className="px-6 py-3">
                {" "}
                {contactList?.getContact?.content
                  ? JSON.parse(contactList?.getContact?.content as string)
                      .question
                  : undefined}
              </td>
            </tr>
          </table>
          <div className="flex justify-center items-center mt-5 mb-5">
            <button
              className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={() => setShowModal(true)}
            >
              返信
            </button>
          </div>
        </div>
        <div className="mt-8 m-auto max-w-3xl justify-center">
          {contactList?.getContact?.title ? (
            JSON.parse(contactList?.getContact?.title as string).answer ? (
              <>
                <div className="border border-gray-200 rounded-lg shadow">
                  <div className="mt-5">
                    <span className="font-bold text-2xl">返信情報</span>
                  </div>
                  <table className="mt-8 text-start m-auto w-[75%]">
                    <tr>
                      <td className="px-6 py-3 font-semibold">ID</td>
                      <td className="px-6 py-3">
                        {contactList?.getContact?.id}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 font-semibold">ニックネーム</td>
                      <td className="px-6 py-3">
                        {contactList?.getContact?.nickname}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 font-semibold">
                        メールアドレス:
                      </td>
                      <td className="px-6 py-3">
                        {contactList?.getContact?.email}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 font-semibold">タイトル</td>
                      <td className="px-6 py-3">
                        {
                          JSON.parse(contactList?.getContact?.title as string)
                            .answer
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-3 font-semibold">
                        お問い合わせ内容:
                      </td>
                      <td className="px-6 py-3">
                        {
                          JSON.parse(contactList?.getContact?.content as string)
                            .answer
                        }
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            ) : null
          ) : null}
        </div>
        <div>
          {showModal ? (
            <>
              <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setShowModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="mt-3 sm:flex">
                      <div className="mt-2 sm:ml-4 sm:text-left">
                        <h4 className="text-lg font-bold text-gray-800">
                          お問い合わせ返信
                        </h4>
                        <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                          <div className="relative">
                            <div>タイトル</div>
                            <div className="mb-10">
                              <input
                                type="text"
                                className="border form-input w-[300px] md:w-[350]"
                                value={title}
                              />
                            </div>
                            <div>内容</div>
                            <div className="mb-10">
                              <textarea
                                className="form-input px-4 w-[300px] md:w-[400px] h-[300px]"
                                onChange={getContent}
                                value={content}
                              ></textarea>
                            </div>
                            <div className="mt-10">
                              <div className="items-center gap-2 mt-3 sm:flex">
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                  onClick={() => setShowModal(false)}
                                >
                                  キャンセル
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => onsubmit()}
                                >
                                  送信
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
