import React, { useEffect, useState } from "react";
import { searchQuery } from "../composable/useSearchable";
import * as queries from "../graphql/queries";
import { ListCategoryMastersQuery } from "../API";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as mutations from "../graphql/mutations";
import { updateMutation } from "../composable/useMutations";

const variables = {};

export const CategoryMaster: React.VFC = () => {
  const [showModal, setShowModal] = useState(false);
  const [categoryMasterList, setCategoryMasterList] = useState<
    ListCategoryMastersQuery | undefined
  >();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [category, setCategory] = useState("");
  const [typeName, setTypeName] = useState("");
  // UUID
  const id = uuidv4();

  async function createCategory() {
    const categoryData = {
      id: id,
      category: category,
      typeName: typeName,
    };
    const result = await updateMutation(
      mutations.updateCategoryMaster,
      categoryData
    );
    if (result) {
      window.location.reload();
    }
  }

  useEffect(() => {
    const getData = async () => {
      const result = await searchQuery(queries.listCategoryMasters, variables);
      setCategoryMasterList(result.data);
    };
    getData();
  }, []);

  return (
    <>
      <div className="m-auto">
        <div className="pt-8">
          <span className="text-4xl font-bold ml-5">カテゴリーマスター</span>
        </div>
        <div className="p-10">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
            onClick={() => setShowModal(true)}
          >
            カテゴリー追加
          </button>
        </div>
        <table className="m-auto border-2 mt-8">
          <thead>
            <tr className="text-center">
              <th className="px-6 py-3 bg-indigo-500 text-white">ID</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">カテゴリー</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">
                参照テーブル
              </th>
              <th className="px-6 py-3 bg-indigo-500 text-white">登録日</th>
              <th className="px-6 py-3 bg-indigo-500 text-white">更新日</th>
            </tr>
          </thead>
          <tbody>
            {categoryMasterList?.listCategoryMasters?.items.map(
              (categoryMaster: any) => {
                return (
                  <>
                    <tr className="text-center">
                      <td className="px-6 py-3 text-indigo-500">
                        <Link
                          to={categoryMaster?.id}
                          state={{ id: categoryMaster?.id }}
                        >
                          {categoryMaster?.id}
                        </Link>
                      </td>
                      <td className="px-6 py-3">{categoryMaster?.category}</td>
                      <td className="px-6 py-3">{categoryMaster?.typeName}</td>
                      <td className="px-6 py-3">
                        {new Date(categoryMaster?.createdAt).toLocaleString()}
                      </td>
                      <td className="px-6 py-3">
                        {new Date(categoryMaster?.updatedAt).toLocaleString()}
                      </td>
                    </tr>
                  </>
                );
              }
            )}
          </tbody>
        </table>
      </div>
      {showModal ? (
        <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className=" sm:flex">
                <div className=" sm:text-left">
                  <h4 className="text-lg font-bold text-gray-800">
                    カテゴリー作成
                  </h4>
                  <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                    <div>カテゴリー名</div>
                    <div className="mb-10">
                      <input
                        type="text"
                        className="border form-input w-[300px] md:w-[350]"
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}
                      />
                    </div>
                    <div>参照テーブル</div>
                    <div className="mb-10">
                      <input
                        type="text"
                        className="form-input px-4 w-[300px] md:w-[350]"
                        value={typeName}
                        onChange={(event) => setTypeName(event.target.value)}
                      ></input>
                    </div>
                    <div className="mt-10">
                      <div className="items-center gap-2 mt-3 sm:flex">
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                          onClick={() => setShowModal(false)}
                        >
                          キャンセル
                        </button>
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                          onClick={() => setShowConfirmModal(true)}
                        >
                          登録
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showConfirmModal ? (
        <div className="fixed m-auto inset-0 z-10 overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full bg-black opacity-40"
            onClick={() => setShowConfirmModal(false)}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
              <div className="mt-3 sm:flex">
                <div className="mt-2 sm:ml-4 sm:text-left">
                  <h4 className="text-lg font-bold text-gray-800">
                    カテゴリーを作成しますか？
                  </h4>
                  <div className="mt-5 md:mt-10 m-auto text-start text-gray-500 p-3">
                    <div className="relative">
                      <div className="mt-10">
                        <div className="items-center gap-2 mt-3 sm:flex">
                          <button
                            className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500 hover:bg-red-700 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                            onClick={() => setShowConfirmModal(false)}
                          >
                            キャンセル
                          </button>
                          <button
                            className="w-full mt-2 p-2.5 flex-1 text-white bg-indigo-500 hover:bg-indigo-700 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                            onClick={() => createCategory()}
                          >
                            カテゴリー作成
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
