import logo from "../../assets/logo.png";
import {
  withAuthenticator,
  WithAuthenticatorProps,
} from "@aws-amplify/ui-react";

interface Props extends WithAuthenticatorProps {
  isPassedToWithAuthenticator: boolean;
}

function NotAuth({ signOut }: Props) {
  return (
    <>
      <div className="error-box blue-grey lighten-5">
        <div className="py-12">
          <div className="items-center text-center">
            <img className="m-auto" src={logo} alt="" />
            <h1 className="text-9xl font-black">Not Auth!</h1>
            <h3 className="text-uppercase error-subtitle mt-15 mb-15">
              管理者権限がありません。
            </h3>
            <h4 className="text-grey-darken-1 mt-15 mb-15">
              管理者に連絡してください。 090-0000-0000
            </h4>
            <button onClick={signOut}>ログイン画面へ</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withAuthenticator(NotAuth);
