/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      isAdmin
      adminName
      email
      phoneNumber
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isAdmin
        adminName
        email
        phoneNumber
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategoryMaster = /* GraphQL */ `
  query GetCategoryMaster($id: ID!) {
    getCategoryMaster(id: $id) {
      id
      typeName
      category
      createdAt
      updatedAt
    }
  }
`;
export const listCategoryMasters = /* GraphQL */ `
  query ListCategoryMasters(
    $filter: ModelCategoryMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      receptionNumber
      nickname
      email
      title
      content
      imageUrl
      status
      createdAt
      updatedAt
      contactCategoryId
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        receptionNumber
        nickname
        email
        title
        content
        imageUrl
        status
        createdAt
        updatedAt
        contactCategoryId
      }
      nextToken
    }
  }
`;
export const getFaq = /* GraphQL */ `
  query GetFaq($id: ID!) {
    getFaq(id: $id) {
      id
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      title
      content
      imageUrl
      isView
      createdAt
      updatedAt
      faqCategoryId
    }
  }
`;
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        title
        content
        imageUrl
        isView
        createdAt
        updatedAt
        faqCategoryId
      }
      nextToken
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      title
      category {
        id
        typeName
        category
        createdAt
        updatedAt
      }
      content
      imageUrl
      isView
      startTime
      endTime
      viewCount
      createdAt
      updatedAt
      noticeCategoryId
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        category {
          id
          typeName
          category
          createdAt
          updatedAt
        }
        content
        imageUrl
        isView
        startTime
        endTime
        viewCount
        createdAt
        updatedAt
        noticeCategoryId
      }
      nextToken
    }
  }
`;
export const getProcess = /* GraphQL */ `
  query GetProcess($id: ID!) {
    getProcess(id: $id) {
      id
      type
      content
      createdAt
      updatedAt
    }
  }
`;
export const listProcesses = /* GraphQL */ `
  query ListProcesses(
    $filter: ModelProcessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventBanner = /* GraphQL */ `
  query GetEventBanner($id: ID!) {
    getEventBanner(id: $id) {
      id
      title
      pageUrl
      imageUrl
      isView
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;
export const listEventBanners = /* GraphQL */ `
  query ListEventBanners(
    $filter: ModelEventBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        pageUrl
        imageUrl
        isView
        startTime
        endTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
